<template>
  <div class="app-container">
    <eHeader :dptrees="dptrees" :query="query"/>
    <!--表格渲染-->
    <tree-table v-loading="loading" :data="data" :expand-all="true" :columns="columns" stripe size="small" style="width: 100%;">
      <el-table-column :show-overflow-tooltip="true" prop="dimensionValue" label="维度值"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" prop="comment" label="备注"/>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSIONTREE_ALL','SRPERMISSIONDIMENSIONTREE_EDIT'])" :data="scope.row" :sup_this="sup_this" :dptrees="dptrees"/>
          <el-popover
            v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSIONTREE_ALL','SRPERMISSIONDIMENSIONTREE_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="200">
            <p>确定删除吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="pr imary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </tree-table>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import treeTable from '@/components/TreeTable'
import initData from '@/mixins/initData'
import { getDimensionTreeList,delDPTree } from '@/api/system/dataper'
import eHeader from '@/components/system/dataper/dptree/header'
import edit from '@/components/system/dataper/dptree/edit'
export default {
  name:'dptree',
  components: { eHeader, edit, treeTable },
  mixins: [initData],
  data() {
    return {
      columns: [
        {
          text: '维度名称',
          value: 'dimensionName'
        }
      ],
      dptrees:[],
      delLoading: false, sup_this: this,
    }
  },
  created() {
    this.getDpTree();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = 'user/crm/srPermissionDimensionTree/getTree';
      // this.params = {
      //   value: this.query.value
      // }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      delDPTree(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getDpTree(){
      getDimensionTreeList().then(res => {
        this.dptrees=res;
      });
    }
  }
}
</script>

<style scoped>

</style>
