<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增纬度值' : '编辑纬度值'" append-to-body width="600px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="100px">
      <el-form-item label="维度类别" prop="dimensionId">
        <el-input v-model="form.dimensionName" placeholder="维度名称" style="width: 460px;"/>
      </el-form-item>
      <el-form-item label="维度名称" prop="dimensionName">
        <el-input v-model="form.dimensionName" placeholder="维度名称" style="width: 460px;"/>
      </el-form-item>
      <el-form-item label="纬度值" prop="dimensionValue">
        <el-input v-model.number="form.dimensionValue" placeholder="纬度值" style="width: 460px;"/>
      </el-form-item>
      <el-form-item label="上级维度" prop="pid">
        <treeselect v-model="form.pid" :options="dptrees" style="width: 460px;" placeholder="上级维度" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addDPTree, editDPTree } from '@/api/system/dataper'
import Treeselect from '@riophae/vue-treeselect'
import IconSelect from '@/components/IconSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect, IconSelect },
  props: {
    dptrees: {
      type: Array,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      form: { 
        dimensionId: '', 
        dimensionName: '', 
        dimensionValue: '', 
        pid: '', 
        status: 1
      },
      rules: {
        dimensionName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        dimensionValue: [
          { required: true, message: '请输入序号', trigger: 'blur'}
        ],
        pid: [
          { required: true, message: '请选择菜单类型', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        } else {
          return false
        }
      })
    },
    doAdd() {
      addDPTree(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: res.message,
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      editDPTree(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: res.message,
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        dimensionId: '', 
        dimensionName: '', 
        dimensionValue: '', 
        pid: '', 
        status: 1 
      }
    }
  }
}
</script>

<style scoped>

</style>
